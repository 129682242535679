import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  stenaArrowDown,
  stenaArrowRight,
  stenaCheck,
  stenaClock,
  stenaDotFilled,
  stenaExclamationTriangle,
  stenaSailingOnRoute,
  stenaSailingTrailerPu,
  stenaSailingTrain,
  stenaSailingTruckSide,
  stenaStatusBlock,
  stenaStatusCanceled,
  stenaStatusConfirmed,
  stenaStatusDone,
  stenaStatusInProgress,
  stenaStatusInProgressAlt,
  stenaStatusNoShow,
} from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { BookingTagQuayStatus, BookingTagStatus } from '../../common-ui/BookingStatusTag';
import {
  BookingFilterState,
  BookingQuayStatus,
  BookingStatus,
  BookingStatus as GQLBookingStatus,
  IntermodalBookingStatus,
} from '../../gql/graphql';
import { exhaustSwitchCase } from './ExhaustSwitchCase';

const TAG_BACKGROUND_COLOR_INFO = cssColor('--lhds-color-blue-100');
const TAG_BACKGROUND_COLOR_PASSIVE = cssColor('--silver-lighter');
const TAG_BACKGROUND_COLOR_WHITE = '#fff';
const TAG_BACKGROUND_COLOR_SUCCESS = cssColor('--lhds-color-green-100');
const TAG_BACKGROUND_COLOR_SUCCESS_STRONG = cssColor('--lhds-color-green-200');
const TAG_BACKGROUND_COLOR_WARNING = cssColor('--lhds-color-orange-100');
const TAG_BACKGROUND_COLOR_ERROR = cssColor('--lhds-color-red-100');

export interface BookingStatusInformation {
  icon: IconDefinition;
  label: string;
  backgroundColor?: string;
}

export const resolveFullStatusText = (
  status: BookingTagStatus,
  quayStatus?: BookingTagQuayStatus | null,
): string => {
  if (status.status === BookingStatus.CheckedInWaitlist) {
    return 'Waitlist - Checked in';
  }
  if (status.status === BookingStatus.Shipped && quayStatus) {
    return quayStatus.label;
  }
  if (status.status === BookingStatus.CheckedIn && quayStatus) {
    return quayStatus.label;
  }
  return status.label;
};

export type CommonBookingTagStatus =
  | 'Block'
  | 'Confirmed'
  | 'WaitingList'
  | 'CheckedIn'
  | 'CheckedInLoaded'
  | 'CheckedInWaitlist'
  | 'Shipped'
  | 'Cancelled'
  | 'NoShow'
  | 'ShippedReadyForPickup'
  | 'ShippedUnloaded'
  | 'ShippedOnHold'
  | 'ShippedGateExit'
  | 'IntermodalEtaPassed'
  | 'IntermodalOnRoute'
  | 'IntermodalInProgress'
  | 'IntermodalDone'
  | 'Other';

export interface BookingStatusTagAppearance {
  icon: IconDefinition;
  backgroundColor?: string;
}

export const resolveTagAppearanceFromCommonBookingStatus = (
  status: CommonBookingTagStatus,
): BookingStatusTagAppearance => {
  switch (status) {
    case 'Block':
      return {
        icon: stenaStatusBlock,
        backgroundColor: TAG_BACKGROUND_COLOR_INFO,
      };
    case 'WaitingList':
      return {
        icon: stenaStatusInProgressAlt,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case 'ShippedReadyForPickup':
      return {
        icon: stenaSailingTruckSide,
        backgroundColor: TAG_BACKGROUND_COLOR_INFO,
      };
    case 'ShippedUnloaded':
      return {
        icon: stenaArrowDown,
        backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
      };
    case 'ShippedOnHold':
      return {
        icon: stenaExclamationTriangle,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case 'ShippedGateExit':
      return {
        icon: stenaArrowRight,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS_STRONG,
      };
    case 'Cancelled':
      return {
        icon: stenaStatusCanceled,
        backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE,
      };
    case 'CheckedIn':
      return {
        icon: stenaCheck,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS_STRONG,
      };
    case 'CheckedInLoaded':
      return {
        icon: stenaStatusDone,
        backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
      };
    case 'CheckedInWaitlist':
      return {
        icon: stenaStatusInProgress,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case 'Confirmed':
      return {
        icon: stenaStatusConfirmed,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS,
      };
    case 'NoShow':
      return {
        icon: stenaStatusNoShow,
        backgroundColor: TAG_BACKGROUND_COLOR_ERROR,
      };
    case 'Shipped':
      return {
        icon: stenaSailingOnRoute,
        backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
      };
    case 'Other':
      return {
        icon: stenaDotFilled,
        backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
      };
    case 'IntermodalEtaPassed':
      return { icon: stenaClock, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case 'IntermodalOnRoute':
      return { icon: stenaSailingTrain, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case 'IntermodalInProgress':
      return { icon: stenaStatusInProgress, backgroundColor: TAG_BACKGROUND_COLOR_WARNING };
    case 'IntermodalDone':
      return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE };
    default:
      return exhaustSwitchCase(status, {
        icon: stenaDotFilled,
        backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
      });
  }
};

export const mapBookingStatus = (
  status: GQLBookingStatus | null,
  quayStatus?: BookingQuayStatus | null,
): CommonBookingTagStatus => {
  switch (status) {
    case GQLBookingStatus.BlockBooking:
      return 'Block';
    case GQLBookingStatus.Cancelled:
      return 'Cancelled';
    case GQLBookingStatus.CheckedIn:
      if (quayStatus === BookingQuayStatus.Loaded) {
        return 'CheckedInLoaded';
      }
      return 'CheckedIn';
    case GQLBookingStatus.CheckedInWaitlist:
      return 'CheckedInWaitlist';
    case GQLBookingStatus.Confirmed:
      return 'Confirmed';
    case GQLBookingStatus.NoShow:
      return 'NoShow';
    case GQLBookingStatus.Waitlist:
      return 'WaitingList';
    case GQLBookingStatus.Shipped: {
      switch (quayStatus) {
        case BookingQuayStatus.ReadyForPickup:
          return 'ShippedReadyForPickup';
        case BookingQuayStatus.Unloaded:
          return 'ShippedUnloaded';
        case BookingQuayStatus.OnHold:
          return 'ShippedOnHold';
        case BookingQuayStatus.GateExit:
          return 'ShippedGateExit';
        default:
          return 'Shipped';
      }
    }
    default:
      return 'Other';
  }
};

export const mapFilterStatus = (status: BookingFilterState): CommonBookingTagStatus => {
  switch (status) {
    case BookingFilterState.Block:
      return 'Block';
    case BookingFilterState.Cancelled:
      return 'Cancelled';
    case BookingFilterState.CheckedIn:
      return 'CheckedIn';
    case BookingFilterState.Confirmed:
      return 'Confirmed';
    case BookingFilterState.NoShow:
      return 'NoShow';
    case BookingFilterState.Shipped:
      return 'Shipped';
    case BookingFilterState.Unloaded:
      return 'ShippedUnloaded';
    case BookingFilterState.WaitingList:
      return 'WaitingList';
    case BookingFilterState.Available:
    case BookingFilterState.CloseToCheckin:
    case BookingFilterState.Incoming:
    case BookingFilterState.None:
    case BookingFilterState.OnQuay:
    case BookingFilterState.PendingApproval:
      return 'Other';
  }
};

export const mapIntermodalStatus = (
  status: IntermodalBookingStatus | null,
): CommonBookingTagStatus => {
  switch (status) {
    case IntermodalBookingStatus.GateExit:
      return 'ShippedGateExit';
    case IntermodalBookingStatus.ReadyForPickup:
      return 'ShippedReadyForPickup';
    case IntermodalBookingStatus.EtaPassed:
      return 'IntermodalEtaPassed';
    case IntermodalBookingStatus.OnRoute:
      return 'IntermodalOnRoute';
    case IntermodalBookingStatus.Confirmed:
    case IntermodalBookingStatus.Arrived:
      return 'Confirmed';
    case IntermodalBookingStatus.Cancelled:
      return 'Cancelled';
    case IntermodalBookingStatus.Waitlist:
      return 'WaitingList';
    case IntermodalBookingStatus.Updated:
    case IntermodalBookingStatus.InProgress:
      return 'IntermodalInProgress';
    default:
      return 'IntermodalDone';
  }
};

export function resolveIntermodalStatusInformation(
  status: IntermodalBookingStatus | null,
): Omit<BookingStatusInformation, 'label'> {
  switch (status) {
    case IntermodalBookingStatus.GateExit:
      return {
        icon: stenaArrowRight,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS,
      };
    case IntermodalBookingStatus.ReadyForPickup:
      return {
        icon: stenaSailingTrailerPu,
        backgroundColor: TAG_BACKGROUND_COLOR_INFO,
      };
    case IntermodalBookingStatus.EtaPassed:
      return { icon: stenaClock, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case IntermodalBookingStatus.OnRoute:
      return { icon: stenaSailingTrain, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case IntermodalBookingStatus.Confirmed:
    case IntermodalBookingStatus.Arrived:
      return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS };
    case IntermodalBookingStatus.Cancelled:
      return { icon: stenaStatusCanceled, backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE };
    case IntermodalBookingStatus.Waitlist:
      return {
        icon: stenaStatusInProgressAlt,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case IntermodalBookingStatus.Updated:
    case IntermodalBookingStatus.InProgress:
      return { icon: stenaStatusInProgress, backgroundColor: TAG_BACKGROUND_COLOR_WARNING };
    default:
      return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE };
  }
}
